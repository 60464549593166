<template>
  <footer class="footer" :style="{ background: $route.meta.footerBg }">
    <div class="footer-mobile__top">
      <div class="wrapper d-flex flex-column align-center justify-center">
        <div class="logo">
          <router-link to="/"
            ><img src="@/assets/icons/Header/logo.png" alt="footer-logo"
          /></router-link>
        </div>
        <div class="title">
          <h3>We want to share our passion with you</h3>
        </div>
        <div class="btn-container">
          <Button :width="375" :color="'#000'" :backGround="'transparent'"
            >write your email</Button
          >
          <Button :width="375" :color="'#fff'" :backGround="'#000'"
            >Subscribe</Button
          >
        </div>
      </div>
    </div>
    <nav class="nav d-flex flex-row align-center space-around">
      <div class="left">
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/shop">Shop</router-link></li>
          <li><router-link to="/about-us">About</router-link></li>
          <li><router-link to="/contact-us">Contacts</router-link></li>
        </ul>
      </div>
      <div class="center">
        <h3>We want to share our passion with you</h3>
        <div class="btn-container">
          <Button :width="375" :color="'#000'" :backGround="'transparent'"
            >write your email</Button
          >
          <Button :width="375" :color="'#fff'" :backGround="'#000'"
            >Subscribe</Button
          >
        </div>
        <div class="social">
          <h5>social</h5>
          <div class="social-icons d-flex flex-row justify-center">
            <router-link to="/"
              ><img
                src="@/assets/icons/Social/instagram.svg"
                alt="instagram"
                class="pointer"
            /></router-link>
            <router-link to="/">
              <img
                src="@/assets/icons/Social/facebook.svg"
                alt="facebook"
                class="pointer"
            /></router-link>
          </div>
        </div>
      </div>
      <div class="right">
        <ul>
          <li><router-link to="/business">For Business</router-link></li>
          <li><router-link to="/delivery">Delivery</router-link></li>
          <li><router-link to="/benefits">Register Benefits</router-link></li>
          <li><router-link to="/app">App</router-link></li>
        </ul>
      </div>
    </nav>
    <div class="mobile-social">
      <div class="social d-flex flex-column align-center justify-center">
        <h5>social</h5>
        <div class="social-icons d-flex flex-row justify-center">
          <router-link to="/"
            ><img
              src="@/assets/icons/Social/instagram.svg"
              alt="instagram"
              class="pointer"
          /></router-link>
          <router-link to="/">
            <img
              src="@/assets/icons/Social/facebook.svg"
              alt="facebook"
              class="pointer"
          /></router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Button from "@/components/Button";

export default {
  name: "Footer",
  components: {
    Button,
  },
};
</script>

