import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      layout: "main",
      headerBg: '#F8F8F8',
      footerBg: '#F8F8F8'
    }
  },
  {
    path: '/app',
    name: 'AppPage',
    component: () => import('../views/AppPage.vue'),
    meta: {
      layout: "main",
      headerBg: '#F8F8F8',
    }
  },
  {
    path: '/benefits',
    name: 'Benefits',
    component: () => import('../views/Benefits.vue'),
    meta: {
      layout: "main",
      headerBg: '#F8F8F8',
    }
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: () => import('../views/Delivery.vue'),
    meta: {
      layout: "main",
      footerBg: '#F8F8F8',
      headerBg: '#F8F8F8',
    }
  },
  {
    path: '/business',
    name: 'Business',
    component: () => import('../views/Business.vue'),
    meta: {
      layout: "main",
      headerBg: '#F8F8F8',
    }
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue'),
    meta: {
      layout: "main",
      footerBg: '#F8F8F8',
      headerBg: '#F8F8F8',
      // specificLayout: true
    }
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue'),
    meta: {
      layout: "main",
      specificLayout: true
    }
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('../views/SignIn.vue'),
    meta: {
      layout: "second",
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router