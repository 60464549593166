<template>
  <div v-if="showScrollToTop">
    <div class="container__scroll-top">
      <div class="top pointer" @click="handleScrollTopClick">
        <img
          src="@/assets/icons/Others/arrowToTheTop.png"
          alt="scroll-top"
          class="arrow-to-the-top"
        />
      </div>

      <div class="bottom pointer">
        <img
          src="@/assets/icons/Others/msg.png"
          alt="scroll-top"
          class="arrow-to-the-top"
        />
      </div>
    </div>
    <div class="mobile-top pointer" @click="handleScrollTopClick">
      <img
        src="@/assets/icons/Others/arrowToTheTop.png"
        alt="scroll-top"
        class="arrow-to-the-top"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ArrowToTheTop",
  data() {
    return {
      showScrollToTop: false,
    };
  },
  methods: {
    handleScrollTopClick() {
      if (window.pageYOffset > 0) {
        window.scroll({ top: 0, behavior: "smooth" });
      }
    },
    handleScroll() {
      this.showScrollToTop =
        window.pageYOffset > document.documentElement.scrollHeight / 10;
    },
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

