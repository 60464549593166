<template>
  <Header />
  <ArrowToTheTop />
  <router-view />
  <Footer />
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ArrowToTheTop from "@/components/ArrowToTheTop";

export default {
  components: {
    Header,
    Footer,
    ArrowToTheTop,
  },
};
</script>

