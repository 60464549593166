<template>
  <Header v-if="isMobileBreakpoint"/>
  <Header2 v-else/>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex'
import Header from "@/components/Header";
import Header2 from "@/components/Header2";
import Footer from "@/components/Footer";

export default {
  components: {
    Header,
    Header2,
    Footer,
  },
  computed: {
    ...mapGetters({
      isMobileBreakpoint: 'isMobileBreakpoint'
    })
  }
};
</script>

