<template>
  <div class="btn-wrapper">
    <button
      class="btn"
      :style="{
        color: this.color,
        'max-width': this.width + 'px',
        background: this.backGround,
      }"
    >
      <slot />
    </button>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    width: {
      type: Number,
      required: false,
      default: 200,
    },
    color: {
      type: String,
      required: false,
      default: "#000",
    },
    backGround: {
      type: String,
      required: false,
      default: "transparent",
    },
  },
};
</script>

