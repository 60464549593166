<template>
  <div class="home">
    <div class="header d-flex flex-row">
      <div class="left d-flex flex-column justify-center">
        <h2>BLOEMENMAKER</h2>
        <h3>Vol Verrassingen</h3>

        <div class="scroll" @click="scrollHeight">
          <img
            src="@/assets/images/Home/scroll.png"
            alt="scroll"
            class="scroll-arrow"
          />
          <img
            src="@/assets/icons/Others/ellipse.png"
            alt="ellipse"
            class="ellipse"
          />
        </div>
      </div>
      <div
        class="right relative"
        :style="{
          backgroundPosition: `${(clientX / windowWidth) * 30 - 15}px ${
            (clientY / windowHeight) * 10 - 5
          }px`,
        }"
      >
        <div
          class="right bg-wrapper full-width full-height"
          :style="{
            backgroundPosition: `${(clientX / windowWidth) * 15 - 15}px ${
              (clientY / windowHeight) * 5 - 5
            }px`,
          }"
        ></div>
        <div class="image-wrapper">
          <img class="girl-cover" src="@/assets/images/Home/girl-cover.png" />
        </div>

        <div class="mobile-wrapper">
          <img
            src="@/assets/images/Home/mobile-bg.png"
            alt="mobile-bg.png"
            class="bg"
          />
        </div>
        <img
          src="@/assets/images/Home/big-flower.png"
          alt="big-flower.png"
          class="big-flower"
        />
      </div>
    </div>
    <div class="flowers-catalog">
      <div class="flowers-catalog__item item1">
        <div class="desc d-flex flex-column align-start">
          <h2>Bonte Lisianthus<span>Valentijn</span></h2>

          <span>€ 22,95</span>

          <img src="@/assets/icons/Others/flower-icon.png" alt="flower-icon" />
        </div>
        <img
          src="@/assets/images/Home/flower1.png"
          alt="flower1"
          class="img-flower"
        />
      </div>
      <div class="flowers-catalog__item item2">
        <div class="desc d-flex flex-column align-start">
          <h2>Bonte Lisianthus<span>Valentijn</span></h2>

          <span>€ 22,95</span>

          <img src="@/assets/icons/Others/flower-icon.png" alt="flower-icon" />
        </div>
        <img
          src="@/assets/images/Home/flower2.png"
          alt="flower2"
          class="img-flower"
        />
        <Button :width="170">VIEW ALL</Button>
      </div>
      <div class="flowers-catalog__item item3">
        <div class="desc d-flex flex-column align-start">
          <h2>Bonte Lisianthus<span>Valentijn</span></h2>

          <span>€ 22,95</span>

          <img src="@/assets/icons/Others/flower-icon.png" alt="flower-icon" />
        </div>
        <img
          src="@/assets/images/Home/flower3.png"
          alt="flower3"
          class="img-flower"
        />
        <Button :width="170" class="mobile-btn">VIEW ALL</Button>
      </div>
    </div>

    <Slider />

    <div class="gray-wrapper">
      <div class="mobile-app d-flex flex-row align-center space-around">
        <div class="left">
          <img
            src="@/assets/icons/Others/our-app.png"
            alt="our-app.png"
            class="app-icon"
          />
          <img
            src="@/assets/images/Home/mobile-app.png"
            alt="mobile-app"
            class="phone"
          />
        </div>

        <div class="right">
          <h3>Share more love with our mobile app</h3>
          <p>
            Collect points and accumulate discounts in the mobile application.
            Send a congratulation video with a bouquet
          </p>
          <Button :width="230" :backGround="'#000'" :color="'#fff'"
            >app features</Button
          >
          <div class="btn-container d-flex flex-row align-baseline">
            <img
              src="@/assets/icons/Others/google-play.png"
              alt="mobile-app"
              class="pointer"
            />
            <img
              src="@/assets/icons/Others/apple-store.png"
              alt="mobile-app"
              class="pointer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Slider from "@/components/Slider";

export default {
  name: "Home",
  data() {
    return { clientX: 0, clientY: 0, windowWidth: 0, windowHeight: 0 };
  },
  components: {
    Button,
    Slider,
  },
  methods: {
    parallaxCalculate(e) {
      this.clientX = e.clientX;
      this.clientY = e.clientY;
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    scrollHeight() {
      window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
    },
  },
  mounted() {
    document.addEventListener("mousemove", this.parallaxCalculate);
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.parallaxCalculate);
  },
};
</script>
