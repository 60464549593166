<template>
  <component :is="layout + '-layout'" v-if="layout" />
</template>


<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import MainLayout from "./layouts/MainLayout.vue";
import SecondLayout from "./layouts/SecondLayout.vue";

export default {
  setup() {
    const route = useRoute();
    const store = useStore ();

    onMounted(() => {
      window.addEventListener('resize', (e) => {
        store.dispatch('setMobileBreakpoint', window.outerWidth <= 500)
      })
    })

    return {
      layout: computed(() => route.meta.layout),
    };
  },
  components: { MainLayout, SecondLayout },
};
</script>
