<template>
  <div class="wrapper">
    <header
      class="header d-flex flex-row justify-between align-center"
      :class="{ painted: isWindowOnScroll && !isOpen }"
    >
      <div
        class="d-flex flex-row justify-between align-center header__left-block"
      >
        <div
          class="burger pointer"
          v-if="!isOpen"
          @click="isOpen = !isOpen"
          :class="{ open: isOpen }"
        >
          <img src="@/assets/icons/Header/burger.svg" alt="burger" />
        </div>

        <div v-if="isOpen">
          <img
            src="@/assets/icons/Header/close.png"
            alt="close"
            class="pointer"
            @click="isOpen = false"
          />
        </div>

        <div class="logo pointer">
          <router-link to="/"
            ><img src="@/assets/icons/Header/logo.png" alt="logo"
          /></router-link>
        </div>
      </div>

      <div
        class="d-flex flex-row justify-between align-center header__right-block"
      >
        <div class="header__sign-in pointer">
          <transition name="menuTransform">
            <router-link to="/sign-in" v-if="isOpen && !isMobile"
              ><h5>Sign in</h5></router-link
            >
          </transition>
        </div>

        <div class="card d-flex flex-row baseline pointer">
          <p class="count-items">0</p>
          <img src="@/assets/icons/Header/basket.png" alt="basket" />
        </div>
      </div>
    </header>

    <div
      class="mobile-header d-flex"
      :style="{ background: $route.meta.headerBg }"
    >
      <div class="logo pointer">
        <router-link to="/"
          ><img src="@/assets/icons/Header/logo.png" alt="logo"
        /></router-link>
      </div>
    </div>

    <transition name="menuTransform">
      <div class="wrapper__inner-menu d-flex flex-row" v-if="isOpen">
        <div class="left d-flex justify-center flex-row">
          <div class="left__items d-flex flex-row justify-center">
            <div class="left__item item1">
              <img src="@/assets/images/Header/flower1.png" alt="flower1" />
            </div>
            <div class="left__item item2">
              <div class="block"></div>
              <img src="@/assets/images/Header/flower2.png" alt="flower2" />
            </div>
            <div class="left__item item3">
              <img src="@/assets/images/Header/flower3.png" alt="flower3" />
            </div>
          </div>
          <div class="privacy d-flex flex-row align-center justify-center">
            <p class="pointer">Privacy Policy</p>
            <p class="pointer">Terms and Conditions</p>
          </div>
        </div>
        <div class="right d-flex flex-column align-start">
          <div class="right__top pointer" v-if="isMobile">
            <router-link to="/sign-in"><h5>Sign in</h5></router-link>
          </div>
          <nav class="d-flex flex-column align-center justify-center">
            <ul class="header-wrapper__inner-list d-flex flex-column">
              <li
                class="
                  d-flex
                  flex-row
                  align-center
                  justify-between
                  pointer
                  relative
                "
                @click="isOpen = false"
              >
                <router-link to="/">Home</router-link>
              </li>

              <li
                class="
                  d-flex
                  flex-row
                  align-center
                  justify-between
                  pointer
                  relative
                "
                @click="isOpen = false"
              >
                <router-link to="/shop">Shop</router-link>
              </li>

              <li
                class="
                  d-flex
                  flex-row
                  align-center
                  justify-between
                  pointer
                  relative
                "
                @click="isOpen = false"
              >
                <router-link to="/about-us">About</router-link>
              </li>

              <li
                class="
                  d-flex
                  flex-row
                  align-center
                  justify-between
                  pointer
                  relative
                "
                @click="isOpen = false"
              >
                <router-link to="/contact-us">Contacts</router-link>
              </li>

              <li
                class="
                  d-flex
                  flex-row
                  align-center
                  justify-between
                  pointer
                  relative
                "
                @click="isOpen = false"
              >
                <router-link to="/business">For Business</router-link>
              </li>
              <li
                class="
                  d-flex
                  flex-row
                  align-center
                  justify-between
                  pointer
                  relative
                "
                @click="isOpen = false"
              >
                <router-link to="/delivery">Delivery</router-link>
              </li>
              <li
                class="
                  d-flex
                  flex-row
                  align-center
                  justify-between
                  pointer
                  relative
                "
                @click="isOpen = false"
              >
                <router-link to="/benefits">Register Benefits</router-link>
              </li>
              <li
                class="
                  d-flex
                  flex-row
                  align-center
                  justify-between
                  pointer
                  relative
                "
                @click="isOpen = false"
              >
                <router-link to="/app">App</router-link>
              </li>
            </ul>
          </nav>
          <div class="policy-mobile">
            <ul>
              <li><router-link to="/">Privacy Policy</router-link></li>
              <li><router-link to="/">Terms and Conditions</router-link></li>
            </ul>
          </div>
          <div class="right__bottom pointer">
            <h5>Need help? <span>Contact us!</span></h5>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Header",

  data() {
    return {
      isOpen: false,
      isWindowOnScroll: false,
      isMobile: false,
    };
  },
  watch: {
    $route(to, from) {
      this.isOpen = false;
    },
    isOpen() {
      console.log(this.isOpen);
      if (this.isOpen) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    },
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  },
  methods: {
    toggleMenu(value) {
      this.isOpen = value;
    },
    navigateTo(url) {
      this.$router.push(url);
    },
    scrollHandler() {
      this.isWindowOnScroll = window.scrollY > 50 ? true : false;
    },
    handleView() {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      }
      return false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler);
    window.removeEventListener("resize", this.handleView);
  },
};
</script>

