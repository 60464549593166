<template>
  <div class="wrapper">
    <header class="header header--2 d-flex flex-row align-center">
      <div class="header__item">
        <router-link to="/shop">Shop</router-link>
      </div>
      <div class="header__item">
        <router-link to="/delivery">Delivery</router-link>
      </div>
      <div class="header__item">
        <router-link to="/contacts">Contacts</router-link>
      </div>
      <div class="logo pointer">
        <img src="@/assets/icons/Header/logo.png" alt="logo" />
      </div>
      <div class="header__item">
        <router-link to="/about">About</router-link>
      </div>
      <div class="header__item header__item--has-sub">
        <router-link to="/benefits">Benefits</router-link>
      </div>
      <div class="header__item">
        <router-link to="/contacts">Contacts</router-link>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

