<template>
  <div
    class="carousel-wrapper relative"
    @mouseover="isMouseOnElement = true"
    @mouseleave="isMouseOnElement = false"
  >
    <img
      src="@/assets/icons/Others/arrow-slider.png"
      alt="arrow"
      class="arrow-slider left pointer"
    />

    <img
      src="@/assets/icons/Others/arrow-slider.png"
      alt="arrow"
      class="arrow-slider right pointer"
    />
    <carousel :settings="settings">
      <slide :key="0">
        <div class="slider d-flex flex-row align-center space-around relative">
          <div class="gray-substrate"></div>
          <div class="left">
            <img
              src="@/assets/images/Slider/slide1.png"
              alt="categories-flower1"
            />
          </div>
          <div class="right">
            <h5>Categories</h5>

            <div class="title d-flex flex-row baseline">
              <img src="@/assets/images/Home/gift.png" alt="gift" />
              <h3>Bedankt</h3>
            </div>
            <p class="subtitle">
              Uit je dankbaarheid met bloemen. Je vind hier boeketten in
              allerlei kleuren en maten.
            </p>
            <Button :width="230">all bouquets</Button>
          </div>
        </div>
      </slide>
      <slide :key="1">
        <div class="slider d-flex flex-row align-center space-around relative">
          <div class="gray-substrate"></div>
          <div class="left">
            <img
              src="@/assets/images/Slider/slide2.png"
              alt="categories-flower2"
            />
          </div>
          <div class="right">
            <h5>Categories</h5>

            <div class="title d-flex flex-row baseline">
              <img src="@/assets/images/AboutUs/flower-maker1.png" alt="gift" />
              <h3>Liefde</h3>
            </div>
            <p class="subtitle">
              Uit je dankbaarheid met bloemen. Je vind hier boeketten in
              allerlei kleuren en maten.
            </p>
            <Button :width="230">all bouquets</Button>
          </div>
        </div>
      </slide>
      <!-- <slide :key="2">
        <p>slide 3</p>
      </slide> -->
      <template #addons>
        <navigation />
      </template>
    </carousel>
  </div>
</template>

<script>
import Button from "@/components/Button";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  name: "Slider",
  data() {
    return {
      isMouseOnElement: false,
      interval: null,
      settings: {
        transition: 3000,
        wrapAround: true,
        mouseDrag: false,
        touchDrag: false,
        pauseAutoplayOnHover: true,
        itemsToShow: 1,
        snapAlign: "center",
      },
    };
  },
  mounted() {
    this.goNext();
    setTimeout(() => {
      this.interval = setInterval(() => {
        this.goNext();
      }, 3000);
    }, 500);
  },
  watch: {
    isMouseOnElement(value) {
      if (!value) {
        this.interval = setInterval(() => {
          this.goNext();
        }, 3000);
      } else {
        clearInterval(this.interval);
      }
    },
  },
  methods: {
    goNext() {
      let buttonsNext = document.getElementsByClassName("carousel__next");
      if (buttonsNext.length > 0) {
        buttonsNext.item(0).click();
      }
    },
  },
  components: {
    Button,
    Carousel,
    Slide,
    Navigation,
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

