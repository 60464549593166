import { createStore } from 'vuex'

export default createStore({
  state: {
    isMobileBreakpoint: false
  },
  getters: {
    isMobileBreakpoint: s => s.isMobileBreakpoint
  },
  mutations: {
    setMobileBreakpoint(state, payload) {
      state.isMobileBreakpoint = payload
    }
  },
  actions: {
    setMobileBreakpoint({ commit }, payload) {
      commit('setMobileBreakpoint', payload)
    }
  },
  modules: {
  }
})
